<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mt-4 mb-3">
        Общие данные
      </h4>
      <!-- <b-form-group label-cols-md="3" id="sharedDataFullNameInputGroup18" label="Контактное лицо" label-for="shared-data-full-name">
        <b-form-input
          id="shared-data-full-name"
          type="text"
          name="shared-data-full-name"
          v-model.trim="$v.form.sharedDataFullName.$model"
          autocomplete="full-name" />
      </b-form-group>-->
      <b-form-group
        id="phoneOneInputGroupMain"
        label-cols-md="3"
        label="Телефон"
        label-for="shared-data-phone-one"
      >
        <b-form-input
          id="shared-data-phone-main"
          v-model.trim="$v.form.phone.$model"
          v-mask="'+7 (999) 999-99-99'"
          type="text"
          name="shared-data-phone-one"
          autocomplete="phone"
          :state="chkState('phone')"
          aria-describedby="sharedDataPhone"
          @blur="phoneValidate($v.form.phone)"
        />
        <b-form-invalid-feedback id="sharedDataPhone">
          <span v-if="!$v.form.phone.required">Поле обязательно для заполнения</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="phoneOneInputGroup19"
        label-cols-md="3"
        label="Дополнительный телефон 1"
        label-for="shared-data-phone-one"
      >
        <b-form-input
          id="shared-data-phone-one"
          v-model.trim="$v.form.phoneOne.$model"
          v-mask="'+7 (999) 999-99-99'"
          type="text"
          name="shared-data-phone-one"
          autocomplete="phone"
          @blur="phoneValidate($v.form.phoneOne)"
        />
      </b-form-group>
      <b-form-group
        id="phoneTwoInputGroup20"
        label-cols-md="3"
        label="Дополнительный телефон 2"
        label-for="shared-data-phone-two"
      >
        <b-form-input
          id="shared-data-phone-two"
          v-model.trim="$v.form.phoneTwo.$model"
          v-mask="'+7 (999) 999-99-99'"
          type="text"
          name="shared-data-phone-two"
          autocomplete="phone"
          @blur="phoneValidate($v.form.phoneTwo)"
        />
      </b-form-group>
      <b-form-group
        id="faxInputGroup21"
        label-cols-md="3"
        label="Факс"
        label-for="shared-data-fax"
      >
        <b-form-input
          id="shared-data-fax"
          v-model.trim="$v.form.fax.$model"
          type="text"
          name="shared-data-fax"
          autocomplete="fax"
        />
      </b-form-group>
      <b-form-group
        id="emailInputGroup22"
        label-cols-md="3"
        label="Email"
        label-for="shared-data-email"
      >
        <b-form-input
          id="shared-data-email"
          v-model.trim="$v.form.email.$model"
          type="email"
          name="shared-data-email"
          autocomplete="email"
          :state="chkState('email')"
          aria-describedby="sharedDataEmail"
        />
        <b-form-invalid-feedback id="sharedDataEmail">
          <span v-if="!$v.form.email.required">Поле обязательно для заполнения</span>
          <span v-if="!$v.form.email.email">Поле принимает только email адрес</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="emailInputGroup223"
        label-cols-md="3"
        label="Email для уведомлений"
        label-for="shared-data-notification-email"
      >
        <b-form-input
          id="shared-data-notification-email"
          v-model.trim="$v.form.notificationEmail.$model"
          type="email"
          name="shared-data-email"
          autocomplete="email"
          :state="chkState('notificationEmail')"
          aria-describedby="sharedDataNotificationEmail"
        />
        <b-form-invalid-feedback id="sharedDataNotificationEmail">
          <span v-if="!$v.form.notificationEmail.email">Поле принимает только email адрес</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        id="ownFleetInputGroup23"
        label-cols-md="3"
        label="Собственный автопарк"
        label-for="shared-data-own-autopark"
      >
        <b-form-input
          id="shared-data-own-autopark"
          v-model.trim="$v.form.ownFleet.$model"
          type="number"
          min="0"
          name="shared-data-own-autopark"
          autocomplete="autopark"
        />
      </b-form-group>
      <b-form-group
        id="attractedFleetInputGroup24"
        label-cols-md="3"
        label="Привлеченный автопарк"
        label-for="shared-data-the-involved-autopark"
      >
        <b-form-input
          id="shared-data-the-involved-autopark"
          v-model.trim="$v.form.attractedFleet.$model"
          type="number"
          min="0"
          name="shared-data-the-involved-autopark"
          autocomplete="autopark"
        />
      </b-form-group>
      <b-form-group
        id="infoInputGroup25"
        label-cols-md="3"
        label="Дополнительная информация"
        label-for="shared-data-additional-information"
      >
        <b-form-textarea
          id="shared-data-additional-information"
          v-model.trim="$v.form.info.$model"
          name="shared-data-additional-information"
          rows="3"
          max-rows="6"
        />
      </b-form-group>

      <div slot="footer">
        <b-button
          v-if="$store.getters.isAdmin"
          type="submit"
          variant="primary"
          :disabled="loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required, minLength, email} from 'vuelidate/lib/validators';
import notifications from '../components/mixins/notifications';
import {contractorRead, contractorUpdate} from '../services/api';
import {validation} from '../components/mixins/validation';

const formShape = {
  phone: '',
  phoneOne: '',
  phoneTwo: '',
  fax: '',
  notificationEmail: '',
  email: '',
  ownFleet: '',
  attractedFleet: '',
  info: '',
};

export default {
  name: 'SharedData',
  mixins: [validationMixin, validation, notifications],
  data() {
    return {
      form: Object.assign({}, formShape),
      feedBack: 'secondary',
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      phone: {
        required,
      },
      phoneOne: '',
      phoneTwo: '',
      fax: '',
      email: {
        required,
        email,
      },
      ownFleet: '',
      attractedFleet: '',
      info: '',
      notificationEmail: {
        email,
      },
    },
  },
  mounted() {
    this.contractorRead();
  },
  methods: {
    phoneValidate(e) {
      if (/_/.test(e.$model)) {
        e.$model = '';
      }
    },
    async contractorRead() {
      this.loading = true;
      const response = await contractorRead(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        for (const [key, value] of Object.entries(this.form)) {
          this.form[key] = response.data.contractor[key];
        }
        if (response.data.contractor.phones[0]) {
          this.form.phoneOne = response.data.contractor.phones[0].number;
        }
        if (response.data.contractor.phones[1]) {
          this.form.phoneTwo = response.data.contractor.phones[1].number;
        }
      }
      this.loading = false;
    },
    async onSubmit() {
      this.form.phones = [];
      if (this.form.phoneOne) {
        this.form.phones.push({
          number: this.form.phoneOne,
          description: 'Основной',
        });
      }
      if (this.form.phoneTwo) {
        this.form.phones.push({
          number: this.form.phoneTwo,
          description: 'Дополнительный',
        });
      }
      if (this.validate()) {
        this.loading = true;
        const response = await contractorUpdate(this.$store.state.user.ownerId, this.formStr);
        if (response && response.status === 200) {
          this.$router.go();
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

